
.body-sz{
  background: linear-gradient(79deg, rgba(65,216,221,1) 3%, rgba(85,131,238,1) 61%);
}


.body-sz .Fila {
    background-color: #EBF5EE;
    border-radius: 15px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800; 
    color: rgba(85,131,238,1);;
    align-self: auto;
    text-align: center;
    line-height: 3vh;
    gap: 5px;
  }
  
  .body-sz .Indicadores { 
    text-align: center;
    flex-wrap: wrap;
    gap: 54px;
    justify-content: center;
    align-items: center;
  }
  
  .body-sz .Indi{
  background: #EBF5EE;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  gap: 54px;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: rgba(85,131,238,1);
  font-family: 'Montserrat';
  font-weight: 400;
  }
  
  .body-sz .Indi2{
    background: #EBF5EE;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    gap: 54px;
    line-height: 8vh;
    color: rgba(85,131,238,1);
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 60px;
  }
  
  .body-sz .Indi21{
    background: #EBF5EE;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    gap: 54px;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: rgba(85,131,238,1);
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 60px;
    line-height: 8vh;
  }
  
  .body-sz .Values{
    color: #ff7c64;
    font-weight: 800;
    font-family: 'Open Sans';
  }
  
  .body-sz .Lizap{
    line-Height: 3.5vh
  }
  
  .body-sz .Avalia{
    display: flex;
    background:#EBF5EE;  
    flex-direction: column;
    align-items: center;
  }
  
  .body-sz .AvaliaTitulo{
    flex-direction: column-reverse;
    color: #EB6515;
    font-weight: 800;
    font-family: 'Open Sans';
  }
  
  .body-sz .atendentes{
    background: #EBF5EE;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    gap: 54px;
    line-height: 8vh;
    color: rgba(85,131,238,1);
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 60px;
  }

  .body-sz .atendentes .lista{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .body-sz .atendentes .lista .agente{
    color: white;
  }
 