@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap');

.body-color-ura{
  background: #F1F1F1;
}

.notify {
    background-color: #ec2121; 
    font-family: 'Inter', sans-serif !important;
    font-weight: 900;
    line-height: 6vh;
    text-align: center;
    color: #FFFFFF;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  }
  
  .Fila {
    background-color: #719EC6;
    border-radius: 15px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 800; 
    color: #F1F1F1;
    align-self: auto;
    text-align: center;
    line-height: 3vh;
    gap: 5px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);

  }
  
  .Indicadores { 
    text-align: center;
    flex-wrap: wrap;
    gap: 54px;
    justify-content: center;
    align-items: center;
  }
  
  .Indi{
  background: #423d3e;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  gap: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  flex-direction: column;
  font-family: 'Inter', sans-serif;
  /* font-weight: 400; */
  position: relative;
  }

  .Indi div:nth-child(1){
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .Indi2{
    background: #EBF5EE;
    box-shadow: 3px 9px 15px rgba(0, 0, 0, 0.90);
    gap: 54px;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 8vh;
    color: #60993E;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 60px;
  }
  
  .Indi21{
    background: #EBF5EE;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    gap: 54px;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #60993E;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 60px;
    line-height: 8vh;
  }
  
  .Values{
    color: #EB6515;
    font-weight: 800;
    font-family: 'Open Sans';
  }
  
  .Lizap{
    line-Height: 3.5vh
  }
  
  .Avalia{
    display: flex;
    background:#EBF5EE;  
    flex-direction: column;
    align-items: center;
  }
  
  .AvaliaTitulo{
    flex-direction: column-reverse;
    color: #EB6515;
    font-weight: 800;
    font-family: 'Open Sans';
  }

  .container-agentes{
      background-color: #FBFBFB; 
      font-family: 'Inter', sans-serif !important;
      font-weight: 900;
      line-height: 7vh;
      text-align: center;
      color: #423D3E;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  }

  .agente{
   width: 93%;
   background-color: rgba(	113, 158, 198, 0.30);
   display: flex;
   flex-direction: row;
   justify-content: space-evenly;
   align-items: center;
   padding: 0 3% 0 4%;
  }

  .agente-status{
    background-color: #cfcfcf;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nome-ramal{
    display: flex;
    flex-direction: column;
    line-height: 1;
    justify-content: flex-start;
    align-items: flex-start;
    /* color: #CF721B; */
  }

  .agente-tempo{
    display: flex;
    justify-content: center;
  }

  .nome-ramal span:nth-child(2){
    font-weight: 300 !important;
  }

  .Fila ul li{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
.Fila ul li h4:nth-child(1){
  background: #F5F5F5 !important;
  /* margin-top: -30px; */
  width: 80%;
  /* border-radius: 10px; */  
}
